<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-encounter-bar v-model="currentEncounterId" @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentEncounter">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="medicalRecords">เวชระเบียน</v-tab>
            <v-tab key="encounterDocuments">เอกสารครั้งนี้</v-tab>
            <v-tab key="prescription">ใบสั่งยา</v-tab>
            <v-tab key="radiology">รังสีวินิจฉัย</v-tab>
            <v-tab key="transactions">ค่าใช้จ่าย</v-tab>
            <v-tab key="doctorfee">ค่าแพทย์</v-tab>
            <v-tab key="appointments">นัดหมาย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="medicalRecords">
              <v-document-list :hn="currentEncounter.hn" folder="default"></v-document-list>
            </v-tab-item>
            <v-tab-item key="encounterDocuments">
              <v-document-editor :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" folder="default" templateCode="MRC-FM-024" find-latest approveOnSave showPrintButton></v-document-editor>
            </v-tab-item>
            <v-tab-item key="prescription">
              <v-document-editor :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" folder="default" templateCode="prescription_opd" find-latest></v-document-editor>
            </v-tab-item>
            <v-tab-item key="radiology">
              <v-radiology-list :hn="currentEncounter.hn"></v-radiology-list>
            </v-tab-item>
            <v-tab-item key="transactions">
              <v-encounter-transaction :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" read-only></v-encounter-transaction>
            </v-tab-item>
            <v-tab-item key="doctorfee">
              <v-encounter-transaction :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" 
                :performDoctorCode="$store.getters.getCurrentUser.doctorCode" :performClinicCode="currentEncounter.clinicCode"
                :performLocationCode="currentEncounter.locationCode"
                :products-filter="{category: 'SV04'}"
                :transactions-filter="{product$category: 'SV04',performDoctorCode: $store.getters.getCurrentUser.doctorCode}"
                title="Doctor Fees"
                hide-toolbar
                :read-only="!!currentEncounter.dischargeDateTime"
              ></v-encounter-transaction>
            </v-tab-item>
            <v-tab-item key="appointments">
              <v-appointment :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :clinicCode="currentEncounter.clinicCode" :doctorCode="currentEncounter.doctorCode"></v-appointment>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-else>
        <v-model-table model-name="Encounters" model-title="รายการลงทะเบียนวันนี้"
          :model-api="['models','Registration','Encounters']" model-key="encounterId"
          :model-api-search="opdEncounterQuery"
          :headers="headers"
          :insertable="false"
        >
          <template v-slot:item.encounterId="props">
            <v-icon v-if="props.item.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
            <v-icon v-else color="red">mdi-calendar-remove</v-icon>
            <label class="font-weight-bold">{{ props.item.encounterId.slice(-3) }}</label>
          </template>
          <template v-slot:item.patient.name_th="props">
            <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
          </template>
          <template v-slot:item.admitDateTime="props">
            <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.locationSubunitCode="props">
            <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
          </template>
          <template v-slot:item.action="props">
            <v-btn color="primary" @click="currentEncounterId=props.item.encounterId">เปิด</v-btn>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import {isUndefined} from 'lodash'

export default {
  mixins: [encounterLoader],
  data: ()=>({
    currentEncounterId: undefined,
    headers: [
      {
        text: 'เลขรับบริการ',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'เวลาเข้าตรวจ',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'สถานที่',
        value: 'location.locationName',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ห้องตรวจ',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '200px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    opdEncounterQuery() {
      let doctorCode = this.$store.getters.getCurrentUser.doctorCode
      let result = {
        encounterType: 'AMB',
        'whereDate#admitDateTime': helperDateTime.today().format('YYYY-MM-DD'),
        doctorCode: doctorCode
      }
      return result
    },
  },
}
</script>

<style lang="css" scoped>
</style>
